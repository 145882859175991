.App {
	text-align: center;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

$default-width: 100vw;
$default-height: 100vh;
$default-padding: 75px;

$text-color: #f5f5f5;
$emphasis-color: #ccc;
$link-color: #d6d600;

$slide-primary-color: #000099;
$slide-secondary-color: #4d0099;
$slide-content-color: #274868;

.presentation-mode {
	.my-slide {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: $default-height;
		padding: $default-padding;
		line-height: 1.5;
		font-size: 1.5em;
		color: $text-color;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		ul,
		ol {
			border: 0;
			margin: 10px auto;
			padding: 0;
			display: inline-block;
			width: 100%;
		}

		&.complex {
			text-align: left;
			flex-direction: column;
			justify-content: flex-start;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p,
			ul,
			ol {
				margin-bottom: 30px;
			}
		}

		ul,
		ol {
			margin: 10px;
			padding-left: 75px;
		}

		ol li {
			list-style: decimal !important;
		}

		ul li {
			list-style: disc;
		}

		p {
			font-size: 1.2em;
		}

		strong,
		em {
			color: $emphasis-color;
		}

		a {
			color: $link-color;
			border-bottom: 3px dotted $link-color;
			text-decoration: none;
		}

		iframe,
		img {
			width: $default-width;
			height: $default-height;
			margin: -$default-padding;
		}

		ul,
		ol,
		li {
			text-align: left;
		}

		blockquote {
			position: relative;
			color: $text-color;
			text-align: left;
			border-left: 10px solid $emphasis-color;
			margin: 1.5em 10px;
			padding: 0.5em 10px;
			padding-left: 50px;
			quotes: "\201C" "\201D" "\2018" "\2019";
		}

		blockquote:before {
			color: #ccc;
			content: open-quote;
			position: absolute;
			left: 10px;
			top: 30px;
			font-size: 4em;
			line-height: 0.1em;
			margin-right: 0.25em;
			vertical-align: -0.4em;
		}

		blockquote p {
			display: inline;
		}

		&.primary {
			background-color: $slide-primary-color;
		}

		&.secondary {
			background-color: $slide-secondary-color;
		}

		&.content {
			background-color: $slide-content-color;
		}
	}
}

.button-27 {
	appearance: none;
	background-color: #000000;
	border: 2px solid #1a1a1a;
	border-radius: 15px;
	box-sizing: border-box;
	color: #ffffff;
	cursor: pointer;
	display: inline-block;
	font-family:
		Roobert,
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		Helvetica,
		Arial,
		sans-serif,
		"Apple Color Emoji",
		"Segoe UI Emoji",
		"Segoe UI Symbol";
	font-size: 16px;
	font-weight: 600;
	line-height: normal;
	margin: 0;
	min-height: 60px;
	min-width: 0;
	outline: none;
	padding: 16px 24px;
	text-align: center;
	text-decoration: none;
	transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	width: 300px;
	will-change: transform;
}

.button-27:disabled {
	pointer-events: none;
}

.button-27:hover {
	box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
	transform: translateY(-2px);
}

.button-27:active {
	box-shadow: none;
	transform: translateY(0);
}

.carousel-video {
    width: 100%;
    height: 400px; // Adjust this height based on your requirement
}

.carousel-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.pin-dialog-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.pin-dialog {
    background-color: #171717; /* Dark background */
    border: 5px solid #FE8639; /* Orange border */
    padding: 20px;
    text-align: center;
}

.pin-input-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.pin-input {
    width: 40px;
    height: 40px;
    border: 2px solid #FE8639; /* Orange border */
    background-color: #303030; /* Dark grey background */
    color: white;
    font-size: 24px;
    text-align: center;
}
